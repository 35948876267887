<template>
    <a-card>
        <a-page-header
            title="橙子建站落地页管理"
        />
        <a-form-model
            ref="form"
            :model="form"
            v-bind='layout'
        >
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="建站名称">
                        <a-input
                            v-model="form.adLandingPageName"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                    <a-form-model-item label="模板ID">
                        <a-input
                            v-model="form.adLandingPageTemplateId"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="8">
                    <a-form-model-item label="品牌">
                        <a-select
                            mode="multiple"
                            v-model="form.principalIdList"
                            placeholder="请选择品牌"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                            @change="onChangePrincipal"
                        >
                            <a-select-option
                                v-for="item in principalList"
                                :key="item.id"
                            >
                                {{ item.principal_name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="集客场景">
                        <a-select
                            mode="multiple"
                            v-model="form.customerSceneList"
                            placeholder="请选择"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option
                                v-for="item in customerSceneList"
                                :key="item.code"
                            >
                                {{ item.desc }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                    <a-form-model-item label="车系">
                        <a-select
                            mode="multiple"
                            v-model="form.carSeriesIdList"
                            placeholder="请选择车系"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option
                                v-for="item in seriesList"
                                :key="item.id"
                            >
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="8">
                    <a-form-model-item label="橙子建站状态">
                        <a-select
                            v-model="form.adLandingPageStatus"
                            placeholder="请选择"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option v-for="item in statusList" :key="item.id">{{ item.label }}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="绑定程序化落地页">
                        <a-select
                            v-model="form.isBindGroup"
                            placeholder="请选择"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option key="true">已绑定</a-select-option>
                            <a-select-option key="false">未绑定</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                    <a-form-model-item label="转化率">
                        <a-input-number :min="0" v-model="form.convertRateMin"></a-input-number>
                        ~
                        <a-input-number :min="0" v-model="form.convertRateMax"></a-input-number>
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="8">
                    <a-form-model-item label="广告主">
                        <a-input
                            v-model="form.advertiserName"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="平台账套">
                        <a-input
                            v-model="form.adPlatformAccountName"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col class="flex-end">
                    <a-space>
                        <a-button @click="handleReset">重置</a-button>
                        <a-button type="primary" @click="handleSearch">查询</a-button>
                    </a-space>
                </a-col>
            </a-row>
            <a-row>
                <a-col>
                    <a-space>
                        <a-button type="primary" @click="onBatch">批量更新落地页</a-button>
                    </a-space>
                </a-col>
            </a-row>
        </a-form-model>

        <a-table
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            :rowKey="record => record.id"
            :row-selection="rowSelection"
            :pagination='false'
            class="mt-20"
            @change="handleTableChange"
        >
            <div
                slot="imgUrl"
                slot-scope="text, record"
            >
                <span v-if="record.thumbnail">
                    <img :src="record.thumbnail" class="img" v-if="record.adLandingPageStatus == 'DELETED'">
                    <img :src="record.thumbnail" class="img" v-else @click="handlePreviewOpen(record.thumbnail, 'img')">
                </span>
                <span v-else>-</span>
            </div>
        </a-table>

        <base-pagination
            :currentPage="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            @onChange="handlePaginationChange"
            @onShowSizeChange="handlePaginationChange"
        />

        <div class="mt-20">
            <div>PS：</div>
            <div>1、累计访问量和转化率统计数据截止2天前，例如：今天是2023年1月3日，系统统计数据截止2023年1月2日</div>
            <div>2、每天凌晨系统会新建满足条件的程序化落地页和为未满10个落地页的程序化落地页添加满足条件的落地页（优先绑定新建的落地页）</div>
            <div>3、“批量更新落地页”功能是让选中的落地页获取当前广告平台上最新的橙子建站状态和建站名称</div>
        </div>

        <preview-modal
            :visible="previewVisible"
            :preview-src="previewSrc"
            width="50%"
            @cancel="handlePreviewCancel"
        />
    </a-card>
</template>

<script>
import utils from "@/utils/FormatUtil.js";
import previewModal from "@/components/PreviewModalVideo/PreviewModalVideo";

const statusList = [
    { id: "ENABLE", label: "已发布, 已生效" },
    { id: "DISABLE", label: "不可用" },
    { id: "DELETED", label: "已删除" },
    { id: "EDIT", label: "未发布, 未生效" },
    { id: "FORBIDDEN", label: "惩罚广告主, 站点下线" },
    { id: "AUDIT_ACCEPTED", label: "审核通过" },
    { id: "AUDIT_REJECTED", label: "审核拒绝" },
    { id: "AUDIT_BANNED", label: "审核封禁" },
    { id: "AUDIT_DOING", label: "审核中" },
]


export default {
    components: {
        previewModal
    },
    data() {
        return {
            layout: {
                labelCol: { span: 9 },
                wrapperCol: { span: 15 }
            },
            form: {},
            loading: false,
            columns: [
                {
                    align: 'center',
                    title: '橙子建站状态',
                    customRender:(text, row) => {
                        return (statusList.filter(item => item.id == row.adLandingPageStatus))[0].label
                    }
                },
                {
                    align: 'center',
                    title: '站点缩略图',
                    scopedSlots: { customRender: 'imgUrl' },
                },
                {
                    align: 'center',
                    title: '建站名称',
                    dataIndex: 'videoTitle',
                    customRender:(text, row) => {
                        let txt1 = <div>
                                <div><a on-click={() => this.handlePreviewOpen(row.previewUrl, 'url')}>{ row.name }</a></div>
                                <div class="txt"><span>站点ID: {row.adLandingPageId}</span><span>模板ID: {row.adLandingPageTemplateId}</span></div>
                            </div>
                        let txt2 = <div>
                                <div>{row.name}</div>
                                <div class="txt"><span>站点ID: {row.adLandingPageId}</span><span>模板ID: {row.adLandingPageTemplateId}</span></div>
                            </div>
                        return row.adLandingPageStatus == 'DELETED' ? txt2 : txt1
                    }
                },
                {
                    align: 'center',
                    title: '品牌',
                    dataIndex: 'principalName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '车系',
                    dataIndex: 'carSeriesName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '集客场景',
                    dataIndex: 'customerSceneDesc',
                    customRender(text) {
                        return text ||'-'
                    }
                },
                {
                    align: 'center',
                    title: '落地页适用范围',
                    dataIndex: 'templateScope',
                    customRender: (text, row) => {
                        return row.templateScope == 1 ? '车系' : row.templateScope == 2 ? '品牌' : '-'
                    }
                },
                {
                    align: 'center',
                    title: '绑定程序化落地页',
                    customRender: (text, row) => {
                        return row.isBindGroup ? '已绑定' : '未绑定'
                    }
                },
                {
                    align: 'center',
                    title: '累计访问量',
                    dataIndex: 'viewCount',
                    sorter: true,
                    customRender: (text, row) => {
                        return (row.viewCount ?? '') !== '' ? utils.humanReadable(row.viewCount, 2) : '-'
                    }
                },
                {
                    align: 'center',
                    title: '转化率',
                    dataIndex: 'convertRate',
                    sorter: true,
                    customRender: (text, row) => {
                        return (row.convertRate ?? '') !== '' ? `${(row.convertRate).toFixed(2)}%` : '-'
                    }
                },
                {
                    align: 'center',
                    title: '广告主',
                    dataIndex: 'advertiserName',
                    customRender(text) {
                        return text || '-'
                    }
                },
            ],
            dataList: [],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0
            },
            selectedRowKeys: [],
            principalList: [],
            seriesList: [],
            customerSceneList: [],
            previewSrc: '',
            statusList,
            previewVisible: false,
            previewSrc: '',
        }
    },
    computed: {
        rowSelection() {
            const { selectedRowKeys } = this;
            return {
                selectedRowKeys,
                onChange: (selectedRowKeys) => {
                    this.selectedRowKeys = selectedRowKeys
                },
                getCheckboxProps: record => ({
                    props: {
                        disabled: record.adLandingPageStatus == 2,
                        // state: record.state
                    },
                })
            };
        },
    },
    created() {
        this.getPrincipalList()
        this.getPrincipalSeriesList()
        this.getCustomerScene()
        this.getDataList()
    },
    methods: {
        handlePreviewOpen(url, type) {
            if(type == 'url') {
                window.open(url)
            } else {
                this.previewVisible = true
                this.previewSrc = url
            }
        },
        handlePreviewCancel() {
            this.previewVisible = false
            this.previewSrc = ''
        },
        getPrincipalList() {
            this.$api.base_api.getPrincipalList().then(res => {
                if(res.code == 200) {
                    this.principalList = res.data
                } else {
                    this.principalList = []
                    console.error(`获取主体失败，${res}`)
                }
            })
        },
        onChangePrincipal(id) {
            this.getPrincipalSeriesList(id)
        },
        getPrincipalSeriesList(id) {
            let params = {
                principalIds: id,
                status: [1, 4]
            }
            this.$api.base_api.getPrincipalSeriesList(params).then(res => {
                if(res.code == 200) {
                    this.seriesList = res.data
                } else {
                    this.seriesList = []
                    console.error(`获取车系失败，${res}`)
                }
            })
        },
        getCustomerScene() {
            this.$api.core.materialManage.getCustomerScene().then(res => {
                if(res.code == 200) {
                    this.customerSceneList = res.data
                } else {
                    console.error(`获取集客场景失败，${res}`)
                }
            })
        },
        handleSearch() {
            this.pagination.current = 1
            this.getDataList()
        },
        handleReset() {
            this.form = {}
            this.pagination.current = 1
            this.pagination.pageSize = 10
            this.getDataList()
            this.seriesList = []
            this.getPrincipalSeriesList()
        },
        handleTableChange(pagination, filters, sorter) {
            this.getDataList({
                sortList: [{
                    fieldName: sorter.columnKey,
                    orderType: sorter.order == 'ascend' ? 'asc' : sorter.order == 'descend' ? 'desc' : '',
                }]
            });
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
        getDataList(data={}) {
            this.loading = true
            const _form = this.form
            let params = {
                ..._form,
                ...data,
                page: this.pagination.current,
                size: this.pagination.pageSize
            }
            this.$api.core.materialManage.getLandingPageList(params).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    this.dataList = res.data.list || []
                    this.pagination.total = res.data.total || 0
                } else {
                    this.dataList = []
                    this.pagination.total = 0
                    console.error(`获取列表失败${res}`)
                }
            })
        },
        jumpDetailPage(id) {
            this.$router.push({
                path: `/castVideoManage/detail`,
                query: {
                    id,
                }
            })
        },
        onBatch() {
            this.$api.core.materialManage.batchUpdateLandingPage(this.selectedRowKeys).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    this.getDataList()
                    this.$message.success(`操作成功`)
                } else {
                    this.$message.error(`操作失败`)
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 40px;
}
.mt-20 {
    margin-top: 20px;
}
.img {
    width: 60px;
}
.txt {
    color: #ccc;
    span {
        display: inline-block;
        padding-right: 30px;
    }
}
</style>
